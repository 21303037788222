import { applyMiddleware, createStore as reduxCreateStore, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

import reducer from '../reducers'

Sentry.init({
  dsn: 'https://283d8bc740154726a0adb8f93ea64a9b@sentry.io/2718026',
})

const middlewares = [thunk]

if (process.env !== 'production') {
  middlewares.push(createSentryMiddleware(Sentry))
  // Chrometools Redux Extension enabled
  middlewares.push(createLogger())
}

const windowGlobal = typeof window !== 'undefined' && window

const composeEnhancers = windowGlobal.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createStore = () =>
  reduxCreateStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))
export default createStore
