import queryString from 'query-string'

import * as apiApply from '../api/apply'

const initState = {
  progress: 0,
  type: 'health',
  step: 'zip',
  highestStep: 'zip',
  data: {},
  overage: true,
  api: {
    pending: false,
    error: false,
    message: '',
  },
  src: '',
  campaign: '',
  bypassZip: false,
  includeAddress: false,
  altFlow: false,
  Sub_ID: '',
  Pub_ID: '',
}

const flows = {
  medicare: {
    0: {
      key: 'zip',
      progress: 0,
    },
    1: {
      key: 'dobmonth',
      progress: 17,
    },
    2: {
      key: 'dobday',
      progress: 33,
    },
    3: {
      key: 'dobyear',
      progress: 50,
    },
    4: {
      key: 'existing',
      progress: 66,
    },
    5: {
      key: 'gender',
      progress: 83,
    },
    6: {
      key: 'address',
      progress: 98,
    },
    7: {
      key: 'pii',
      progress: 100,
    },
    8: {
      key: 'final',
      progress: 100,
    },
  },
  health: {
    0: {
      key: 'zip',
      progress: 0,
    },
    1: {
      key: 'dobmonth',
      progress: 8,
    },
    2: {
      key: 'dobday',
      progress: 16,
    },
    3: {
      key: 'dobyear',
      progress: 25,
    },
    4: {
      key: 'gender',
      progress: 33,
    },
    5: {
      key: 'income',
      progress: 45,
    },
    6: {
      key: 'household',
      progress: 55,
    },
    7: {
      key: 'height',
      progress: 65,
    },
    8: {
      key: 'weight',
      progress: 75,
    },
    9: {
      key: 'tobacco',
      progress: 88,
    },
    10: {
      key: 'address',
      progress: 95,
    },
    11: {
      key: 'pii',
      progress: 100,
    },
    12: {
      key: 'final',
      progress: 100,
    },
  },
}

// Action names
const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
const UPDATE_STEP = 'UPDATE_STEP'
const UPDATE_HIGHEST_STEP = 'UPDATE_HIGHEST_STEP'
const UPDATE_TYPE = 'UPDATE_TYPE'
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
const SUBMIT_APPLICATION_PENDING = 'SUBMIT_APPLICATION_PENDING'
const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS'
const SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE'
const UPDATE_SRC = 'UPDATE_SRC'
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
const TOGGLE_BYPASS_ZIP = 'TOGGLE_BYPASS_ZIP'
const TOGGLE_INCLUDE_ADDRESS = 'TOGGLE_INCLUDE_ADDRESS'
const TOGGLE_FIELD_VALUE = 'TOGGLE_FIELD_VALUE'

// Action Handlers
export const checkUrlSrc = (search) => (dispatch, getState) => {
  const parsed = queryString.parse(search)
  if (parsed.SRC && getState().captive.src === '') {
    dispatch({ type: UPDATE_SRC, payload: parsed.SRC })
  }

  if (parsed.Sub_ID && getState().captive.Sub_ID === '') {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'Sub_ID',
        value: parsed.Sub_ID,
      },
    })
  }

  if (parsed.Pub_ID && getState().captive.Pub_ID === '') {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'Pub_ID',
        value: parsed.Pub_ID,
      },
    })
  }
}
export const checkUrlCampaign = (search) => (dispatch, getState) => {
  const parsed = queryString.parse(search)
  if (parsed.utm_campaign && getState().captive.campaign === '') {
    dispatch({ type: UPDATE_CAMPAIGN, payload: parsed.utm_campaign })
  }
}

export const checkPrePopulatedData = (search) => (dispatch) => {
  const parsed = queryString.parse(search)
  var prePopulatedMapping = {
    type: 'type',
    first: 'first',
    last: 'last',
    gender: 'gender',
    dob: 'dob',
    address: 'address',
    zip: 'zip',
    phone: 'phone',
    email: 'email',
  }
  let dataKey

  for (dataKey in prePopulatedMapping) {
    if (dataKey in parsed) {
      let dataMap = prePopulatedMapping[dataKey]
      let paramVal = parsed[dataKey]
      if (dataKey === 'dob') {
        const dob = new Date(paramVal)
        let now = new Date()
        let diffMs = now.getTime() - dob.getTime()
        let diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)
        let newType = initState.type
        let overAge = initState.overage
        let overFiftyFive = false

        if (diffYears <= 64.5) {
          newType = 'health'
          overAge = false
        }
        if (diffYears > 55) {
          overFiftyFive = true
        }
        dispatch({ type: UPDATE_TYPE, payload: newType })

        dispatch({
          type: UPDATE_FORM_DATA,
          payload: {
            day: dob.getDate(),
            month: dob.getMonth() + 1,
            year: dob.getFullYear(),
            overage: overAge,
            age: diffYears,
            overfiftyfive: overFiftyFive,
          },
        })
      } else if (
        dataKey === 'type' &&
        (paramVal === 'health' || paramVal === 'medicare')
      ) {
        dispatch({ type: UPDATE_TYPE, payload: paramVal })
      } else {
        dispatch({ type: UPDATE_FORM_DATA, payload: { [dataMap]: paramVal } })
      }
    }
  }
}

export const submitZip = (zip) => (dispatch) => {
  dispatch({ type: UPDATE_FORM_DATA, payload: { zip: zip } })
  return true
}

export const submitDob = (year) => (dispatch, getState) => {
  if (Number.isInteger(year)) {
    year = year + ''
  }
  year = year.length === 2 ? '19' + year : year
  let month = getState().captive.data.month
  let day = getState().captive.data.day
  let now = new Date()
  let dob = new Date(year, month - 1, day)
  let diffMs = now.getTime() - dob.getTime()
  let diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)
  let newType = initState.type
  let overAge = initState.overage
  let overFiftyFive = false

  if (diffYears <= 64.5) {
    newType = 'health'
    overAge = false
  } else if (diffYears > 64.5) {
    newType = 'medicare'
  }

  if (diffYears > 55) {
    overFiftyFive = true
  }

  dispatch({ type: UPDATE_TYPE, payload: newType })

  dispatch({
    type: UPDATE_FORM_DATA,
    payload: {
      year: year,
      overage: overAge,
      age: diffYears,
      overfiftyfive: overFiftyFive,
    },
  })
}

export const goToStep = (step, checkHighest = false) => (dispatch, getState) => {
  const nextObject = determineNextStep(getState().captive.type, step, false, true)
  dispatch({ type: UPDATE_PROGRESS, payload: nextObject.progress })
  dispatch({ type: UPDATE_STEP, payload: nextObject.key })
}

export const nextStep = (current, prev = false) => (dispatch, getState) => {
  let nextObject = determineNextStep(getState().captive.type, current, prev)

  // check for bypass of zip
  if (nextObject.key === 'existing' && getState().captive.bypassZip && !prev) {
    nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
  }

  // check for medicareAlt switch to health - NOT YET
  // if (current === "dobyear" && getState().captive.type === "health" && !prev) {
  //   nextObject = determineNextStep(getState().captive.type, "birth", prev)
  //   // Reset the address to show on PII for health alt
  //   dispatch({ type: TOGGLE_INCLUDE_ADDRESS, payload: false })
  // }

  dispatch({ type: UPDATE_PROGRESS, payload: nextObject.progress })
  dispatch({ type: UPDATE_STEP, payload: nextObject.key })
  if (!prev) {
    dispatch({ type: UPDATE_HIGHEST_STEP, payload: nextObject.key })
  }

  return nextObject.key
}

export const determineNextStep = (
  type,
  currentKey,
  prev = false,
  returnMatch = false,
) => {
  let db = flows[type]

  for (var i = 0; i < Object.keys(db).length; i++) {
    if (db[i].key === currentKey) {
      let keyVal = prev ? i - 1 : i + 1
      keyVal = returnMatch ? i : keyVal
      return db[keyVal]
    }
  }

  return db[0]
}

export const submitData = (data) => (dispatch) => {
  dispatch({ type: UPDATE_FORM_DATA, payload: data })
  return true
}

export const toggleBypassZip = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_BYPASS_ZIP, payload: value })
}
export const toggleIncludeAddress = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_INCLUDE_ADDRESS, payload: value })
}
export const toggleField = (field, value) => (dispatch) => {
  dispatch({ type: TOGGLE_FIELD_VALUE, payload: { field, value } })
}

export const preSubmitApplication = () => (dispatch) => {
  dispatch({ type: SUBMIT_APPLICATION_PENDING, payload: true })
}

export const submitApplication = () => (dispatch, getState) => {
  dispatch({ type: SUBMIT_APPLICATION_PENDING, payload: true })
  const { data, type, src } = getState().captive
  const defaultTypeData = { ...data, other: false }
  console.log(data)
  return apiApply
    .submitApplication(type, defaultTypeData, src)
    .then((response) => {
      console.log(response)
      dispatch({
        type: SUBMIT_APPLICATION_SUCCESS,
        payload: response,
      })

      if (data.other) {
        // Handle additional life submission
        apiApply.submitApplication(type, data, src).catch((err) => {
          //Just bypass it
        })
      }

      return response
    })
    .catch((err) => {
      dispatch({
        type: SUBMIT_APPLICATION_FAILURE,
        payload:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
      return Promise.reject(err)
    })
}

// Reducer
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      }
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case UPDATE_HIGHEST_STEP:
      return {
        ...state,
        highestStep: action.payload,
      }
    case UPDATE_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    case UPDATE_FORM_DATA:
      const newData = { ...state.data, ...action.payload }
      return {
        ...state,
        data: newData,
      }
    case SUBMIT_APPLICATION_PENDING:
      return {
        ...state,
        api: {
          pending: action.payload,
          message: '',
          error: false,
        },
      }
    case SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        api: {
          pending: false,
          message: '',
          error: false,
        },
      }
    case SUBMIT_APPLICATION_FAILURE:
      return {
        ...state,
        api: {
          pending: false,
          message: action.payload,
          error: true,
        },
      }
    case UPDATE_SRC:
      return {
        ...state,
        src: action.payload,
      }
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
      }
    case TOGGLE_INCLUDE_ADDRESS:
      return {
        ...state,
        includeAddress: action.payload,
      }
    case TOGGLE_BYPASS_ZIP:
      return {
        ...state,
        bypassZip: action.payload,
      }
    case TOGGLE_FIELD_VALUE:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    default:
      return state
  }
}
